<template>
    <ul class="nav nav-pills navtab-bg">
        <li
            v-for="questionnaire in questionnaires"
            :key="questionnaire.id"
            class="nav-item col-sm-6 col-md-4 col-xl-2 p-0 mb-1"
            style="text-align: center"
        >
            <a
                href="#"
                data-toggle="tab"
                aria-expanded="false"
                class="nav-link ml-0 mr-2"
                :class="{
                    active:
                        activeQuestionnaire &&
                        activeQuestionnaire.id === questionnaire.id
                }"
                @click.prevent="
                    $emit('set-active-questionnaire', questionnaire)
                "
            >
                {{ formatQuestionnaireName(questionnaire.name) }}
            </a>
        </li>
    </ul>
</template>

<script>
import { startCase, toLower } from 'lodash';

export default {
    props: {
        activeQuestionnaire: {
            type: Object,
            required: false,
            default: null
        },
        questionnaires: {
            type: Array,
            required: true
        }
    },

    methods: {
        formatQuestionnaireName(name) {
            return `${startCase(toLower(name.replace('product', '')))}`;
        }
    }
};
</script>

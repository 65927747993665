<template>
    <div>
        <div class="card-box mb-3">
            <div class="row mb-2 col-12 justify-content-between m-0 p-0">
                <button
                    class="btn btn-info waves-effect waves-light mr-1"
                    @click="$bvModal.show('modalQuestionnaireCreateEdit')"
                >
                    <i class="fas fa-plus-circle" />
                    Add new
                </button>
                <div
                    v-if="activeQuestionnaire && activeQuestionnaire.removable"
                >
                    <a
                        class="btn btn-info mr-1"
                        :href="previewUrl"
                        target="_blank"
                    >
                        <i class="far fa-eye mr-1" />
                        Preview
                    </a>
                    <button
                        type="button"
                        class="btn btn-success waves-effect waves-light"
                        @click="showEditModal"
                    >
                        <i class="fas fa-pen mr-1" />
                        Edit
                    </button>
                    <button
                        class="btn btn-danger waves-effect waves-light ml-1"
                        @click="onDeleteQuestionnaire"
                    >
                        <i class="fas fa-trash-alt mr-1" />
                        Delete
                    </button>
                </div>
            </div>

            <h5 class="text-uppercase bg-light p-2 mb-3">
                {{ isQuestionnairePage ? 'Pre Purchase' : 'Questionnaires' }}
            </h5>
            <questionnaires-list
                :active-questionnaire="activeQuestionnaire"
                :questionnaires="prePurchaseQuestionnaires"
                @set-active-questionnaire="setActiveQuestionnaire"
            />

            <h5
                v-if="isQuestionnairePage"
                class="text-uppercase bg-light p-2 mb-3 mt-4"
            >
                Post Purchase
            </h5>
            <questionnaires-list
                v-if="isQuestionnairePage"
                :active-questionnaire="activeQuestionnaire"
                :questionnaires="postPurchaseQuestionnaires"
                @set-active-questionnaire="setActiveQuestionnaire"
            />
        </div>

        <questions-list
            v-if="activeQuestionnaire"
            :questions.sync="questions"
            :is-product-page="isProductPage"
            :is-bundle-page="isBundlePage"
            :active-questionnaire="activeQuestionnaire"
            :reduced-questionnaire="activeQuestionnaire.name === 'reduced'"
        />

        <create-edit-modal
            :updating-questionnaire="!!questionnaireToUpdate"
            :questionnaire="questionnaireToUpdate"
            :questionnaires="questionnaires"
            :is-product-page="isProductPage"
            :is-bundle-page="isBundlePage"
            @hidden="onHideCreateEditModal"
            @create-questionnaire="onCreateQuestionnaire"
            @update-questionnaire="onUpdateQuestionnaire"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { frontendUrl } from '@config';
import QuestionnairesList from '@components/questionnaires/QuestionnairesList';
import QuestionsList from '@components/questionnaires/questions-list/QuestionsList';
import CreateEditModal from '@components/questionnaires/CreateEditModal';

export default {
    components: {
        QuestionnairesList,
        QuestionsList,
        CreateEditModal
    },

    props: {
        questionnaires: {
            type: Array,
            required: true
        },
        productSlug: {
            type: String,
            required: false,
            default: null
        },
        bundleSlug: {
            type: String,
            required: false,
            default: null
        },
        isQuestionnairePage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            activeQuestionnaire: null,
            questionnaireToUpdate: null,
            questions: []
        };
    },

    computed: {
        prePurchaseQuestionnaires() {
            return this.questionnaires.filter(
                questionnaire => !questionnaire.is_post_purchase
            );
        },

        postPurchaseQuestionnaires() {
            return this.questionnaires.filter(
                questionnaire => questionnaire.is_post_purchase
            );
        },

        isProductPage() {
            return !!this.productSlug;
        },

        isBundlePage() {
            return !!this.bundleSlug;
        },

        previewUrl() {
            const questionnaireType = this.activeQuestionnaire.name.startsWith(
                'quiz'
            )
                ? 'quiz'
                : 'questionnaire';

            const questionnaire = `${questionnaireType}?id=${
                this.activeQuestionnaire.name
            }`;

            if (this.productSlug) {
                return `${frontendUrl}/products/${
                    this.productSlug
                }/${questionnaire}`;
            }

            if (this.bundleSlug) {
                return `${frontendUrl}/bundles/${
                    this.bundleSlug
                }/${questionnaire}`;
            }

            return `${frontendUrl}/${questionnaire}`;
        }
    },

    watch: {
        activeQuestionnaire() {
            if (this.activeQuestionnaire) {
                this.fetchQuestions();
            }
        },

        questionnaires(newVal, oldVal) {
            const wereQuestionnairesLoaded = newVal.length && !oldVal.length;

            if (wereQuestionnairesLoaded) {
                this.setInitialQuestionnaire();
            }
        }
    },

    methods: {
        ...mapActions({
            createQuestionnaire: 'questionnaires/store',
            updateQuestionnaire: 'questionnaires/update',
            deleteQuestionnaire: 'questionnaires/delete',
            getQuestions: 'questionnaires/getQuestions'
        }),

        setInitialQuestionnaire() {
            const { questionnaire: activeQuestionnaireId } = this.$route.query;

            if (activeQuestionnaireId) {
                this.activeQuestionnaire = this.questionnaires.find(
                    item => item.id === activeQuestionnaireId
                );
            }
        },

        setActiveQuestionnaire(questionnaire) {
            this.activeQuestionnaire = questionnaire;
        },

        async fetchQuestions() {
            try {
                this.questions = await this.getQuestions(
                    this.activeQuestionnaire.id
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onCreateQuestionnaire(data) {
            try {
                if (this.product) {
                    data.productId = this.product.id;
                } else if (this.bundle) {
                    data.bundleId = this.bundle.id;
                }

                const newQuestionnaire = await this.createQuestionnaire(data);

                this.questionnaires = [
                    ...this.questionnaires,
                    newQuestionnaire
                ];

                this.$toaster('Questionnaire has been created!');
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        },

        async onUpdateQuestionnaire(data) {
            try {
                if (this.product && this.product.id) {
                    data.productId = this.product.id;
                } else if (this.bundle) {
                    data.bundleId = this.bundle.id;
                }

                const questionnaire = await this.updateQuestionnaire(data);

                const questionnaireIndex = this.questionnaires.findIndex(
                    item => item.id === questionnaire.id
                );

                this.activeQuestionnaire = questionnaire;

                this.questionnaires.splice(
                    questionnaireIndex,
                    1,
                    questionnaire
                );

                this.$toaster('Questionnaire has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        },

        async onDeleteQuestionnaire() {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this questionnaire? All questions will be removed.',
                {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            const id = this.activeQuestionnaire.id;

            try {
                await this.deleteQuestionnaire(id);

                this.questionnaires = this.questionnaires.filter(
                    item => item.id !== id
                );

                this.activeQuestionnaire = null;

                this.$toaster('Questionnaire has been deleted!');
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        },

        showEditModal() {
            const { name } = this.activeQuestionnaire;
            let type = name.split('-')[0];

            if (type === 'post') {
                type = 'post-purchase';
            }

            this.questionnaireToUpdate = {
                ...this.activeQuestionnaire,
                type,
                name: name.slice(type.length + 1)
            };

            this.$bvModal.show('modalQuestionnaireCreateEdit');
        },

        onHideCreateEditModal() {
            this.questionnaireToUpdate = null;
        }
    }
};
</script>

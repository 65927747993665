<template>
    <div class="row m-0">
        <div class="col-12 card-box">
            <div class="card-title">
                <button
                    class="btn btn-danger waves-effect waves-light mr-3"
                    @click="onClickButtonNewQuestion"
                >
                    <i class="fas fa-plus" />
                </button>
            </div>
            <b-form-checkbox
                v-model="activeQuestionnaire.progress_bar_enabled"
                switch
                @change="onProgressBarChange"
            >
                Show Progress Bar
            </b-form-checkbox>
            <div v-if="questions.length" class="table-responsive">
                <questions-list-draggable
                    :key="`${questions.length}_${activeQuestionnaire.id}`"
                    :show-color-box="true"
                    :questions="questions"
                    @editElement="onClickEditEditElementButton"
                    @elementDeleted="onDeleteQuestion"
                    @elementMoved="onQuestionMove"
                >
                    <span slot-scope="row">
                        {{ row.element.name }}
                    </span>
                </questions-list-draggable>
            </div>
            <div v-else class="text-center">
                <small>
                    No questions yet
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import QuestionsListDraggable from './QuestionsListDraggable';

export default {
    components: {
        QuestionsListDraggable
    },

    props: {
        questions: {
            type: Array,
            required: false,
            default: () => []
        },
        reducedQuestionnaire: {
            type: Boolean,
            required: false,
            default: false
        },
        isProductPage: {
            type: Boolean,
            required: false,
            default: false
        },
        isBundlePage: {
            type: Boolean,
            required: false,
            default: false
        },
        activeQuestionnaire: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            productId: null,
            bundleId: null
        };
    },

    mounted() {
        if (this.isProductPage) {
            this.productId = this.$route.params.id;
        } else if (this.isBundlePage) {
            this.bundleId = this.$route.params.id;
        }
    },

    methods: {
        ...mapActions({
            deleteQuestion: 'questions/delete',
            changeOrder: 'questionnaires/changeQuestionsOrder',
            toggleProgressBar: 'questionnaires/toggleProgressBar'
        }),

        onClickButtonNewQuestion() {
            const query = {};

            if (this.productId) {
                query.productId = this.productId;
            }

            if (this.bundleId) {
                query.bundleId = this.bundleId;
            }

            if (this.reducedQuestionnaire) {
                query.reduced = true;
            }

            const path = `/questionnaires/${
                this.activeQuestionnaire.id
            }/new`;

            this.$router.push({
                path,
                query
            });
        },

        onClickEditEditElementButton(question) {
            const query = {};

            if (this.productId) {
                query.productId = this.productId;
            }

            if (this.bundleId) {
                query.bundleId = this.bundleId;
            }

            if (this.reducedQuestionnaire) {
                query.reduced = true;
            }

            const path = `/questionnaires/question/${
                question.id
            }/edit`;

            this.$router.push({
                path,
                query
            });
        },

        async onDeleteQuestion(question, index) {
            const confirmed = await this.$confirmationModal(
                'This item already have a scan date would you like to change it?'
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteQuestion(question.id);

                this.questions.splice(index, 1);
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        },

        async onQuestionMove(questions) {
            const orderedArray = questions.map((item, index) => {
                return { ...item, order: index };
            });

            try {
                await this.changeOrder({
                    questionnaireId: this.activeQuestionnaire.id,
                    orderedQuestions: orderedArray
                });

                this.$toaster('Order has been changed!');
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        },

        async onProgressBarChange() {
            await this.$nextTick();

            try {
                await this.toggleProgressBar(this.activeQuestionnaire);

                this.$toaster('Questionnaire has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        }
    }
};
</script>

<template>
    <div class="card-box">
        <div class="row m-0">
            <div class="col-12">
                <h5 class="text-uppercase bg-light p-2">
                    Product List
                </h5>

                <div class="d-flex flex-wrap">
                    <div class="form-group col-4">
                        <label>
                            Orthotics
                        </label>
                        <b-form-select
                            v-model="selectedProduct"
                            :options="productOptions"
                        />
                    </div>

                    <div class="form-group col-2">
                        <label for="productQuantity">
                            Quantity
                        </label>
                        <input
                            id="productQuantity"
                            v-model.number="productQuantity"
                            class="form-control"
                            placeholder="Quantity"
                            type="text"
                        />
                    </div>
                    <div class="form-group col-2">
                        <label for="productPrice">
                            Price
                        </label>
                        <currency-input
                            id="product_price"
                            v-model.number="productPrice"
                            type="text"
                            class="form-control"
                            placeholder="Price"
                        />
                    </div>

                    <div class="form-group col-4 d-flex align-items-end">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="addItem('product')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Orthotics
                        </button>
                    </div>
                </div>

                <div class="d-flex flex-wrap">
                    <div class="form-group col-4">
                        <label>
                            Other Products
                        </label>
                        <b-form-select
                            v-model="selectedSecondaryProduct"
                            :options="secondaryProductOptions"
                        />
                    </div>
                    <div class="form-group col-2">
                        <label for="secondaryProductQuantity">
                            Quantity
                        </label>
                        <input
                            id="secondaryProductQuantity"
                            v-model.number="secondaryProductQuantity"
                            class="form-control"
                            placeholder="Quantity"
                            type="text"
                        />
                    </div>
                    <div class="form-group col-2">
                        <label for="secondaryProductPrice">
                            Price
                        </label>
                        <currency-input
                            id="product_price"
                            v-model.number="secondaryProductPrice"
                            type="text"
                            class="form-control"
                            placeholder="Price"
                        />
                    </div>
                    <div class="form-group col-4 d-flex align-items-end">
                        <button
                            type="button"
                            class="mx-2 btn btn-sm btn-success waves-effect waves-light add-button"
                            @click="addItem('secondaryProduct')"
                        >
                            <i class="mdi mdi-plus" />
                            Add Other Product
                        </button>
                    </div>
                </div>

                <div class="table-responsive p-1 mt-3">
                    <table id="faq-datatable" class="table table-bordered table-striped">
                        <thead>
                            <tr class="text-center">
                                <th>#</th>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tr
                            v-for="(bundleProduct, index) in bundleProducts"
                            :key="index"
                        >
                            <td class="text-center align-middle">
                                {{ index + 1 }}
                            </td>
                            <td class="text-center align-middle">
                                {{
                                    bundleProduct.product
                                        ? bundleProduct.product.name
                                        : bundleProduct.secondaryProduct.name
                                }}
                            </td>
                            <td>
                                <div class="form-group">
                                    <input
                                        v-model.number="bundleProduct.quantity"
                                        class="form-control"
                                    />
                                </div>
                            </td>
                            <td>
                                <div class="form-group">
                                    <currency-input
                                        id="product_price"
                                        v-model.number="bundleProduct.price"
                                        type="text"
                                        class="form-control"
                                    />
                                </div>
                            </td>
                            <td class="text-center align-middle" style="font-size: 18px">
                                <a style="cursor:pointer" @click="updateItem(index)">
                                    <i class="fas fa-save mr-2" />
                                </a>
                                <a style="cursor:pointer" @click.stop.prevent="deleteItem(bundleProduct.id)">
                                    <i class="far fa-trash-alt" />
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapActions } from 'vuex';
import CurrencyInput from '@components/elements/CurrencyInput';

export default {
    components: {
        CurrencyInput
    },

    props: {
        bundleId: {
            type: String,
            required: true
        },
        bundleProductsData: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            bundleProducts: [],
            products: [],
            secondaryProducts: [],
            selectedProduct: {},
            selectedSecondaryProduct: {},
            productQuantity: null,
            productPrice: null,
            secondaryProductQuantity: null,
            secondaryProductPrice: null
        }
    },

    computed: {
        productOptions() {
            return this.products.map(product => {
                return {
                    text: product.name,
                    value: product
                }
            })
        },

        secondaryProductOptions() {
            return this.secondaryProducts.map(product => {
                return {
                    text: product.name,
                    value: product
                }
            })
        }
    },

    async mounted() {
        this.bundleProducts = cloneDeep(this.bundleProductsData);
        this.bundleProducts = this.bundleProducts.map(bundleProduct => ({
            ...bundleProduct,
            price: bundleProduct.price / 100
        }))

        await this.fetchProducts();
        await this.fetchSecondaryProducts();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            getSecondaryProducts: 'secondaryProducts/index',
            createBundleProduct: 'bundleProducts/store',
            updateBundleProduct: 'bundleProducts/update',
            deleteBundleProduct: 'bundleProducts/delete',
        }),

        async fetchProducts() {
            try {
                const { rows } = await this.getProducts();

                this.products = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async fetchSecondaryProducts() {
            try {
                const { rows } = await this.getSecondaryProducts();

                this.secondaryProducts = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async addItem(type) {
            try {
                let bundleProduct = {};

                if (type === 'product' && this.selectedProduct.id) {
                    bundleProduct = {
                        productId: this.selectedProduct.id,
                        quantity: this.productQuantity,
                        price:  Math.round(this.productPrice * 100)
                    }
                } else if (type === 'secondaryProduct' && this.selectedSecondaryProduct.id) {
                    bundleProduct = {
                        secondaryProductId: this.selectedSecondaryProduct.id,
                        quantity: this.secondaryProductQuantity,
                        price:  Math.round(this.secondaryProductPrice * 100)
                    }
                } else {
                    return;
                }

                const createdBundleProduct = await this.createBundleProduct({
                    bundleId: this.bundleId,
                    bundleProduct
                });

                this.selectedProduct = {};

                this.bundleProducts = [
                    ...this.bundleProducts,
                    {
                        ...createdBundleProduct,
                        price: Math.round(createdBundleProduct.price / 100)
                    }
                ];

                this.$toaster('Product has been added!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async updateItem(index) {
            const bundleProduct = this.bundleProducts[index];
            try {
                await this.updateBundleProduct({
                    ...bundleProduct,
                    price: Math.round(bundleProduct.price * 100)
                });

                this.$toaster('Product has been updated!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async deleteItem(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this item?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteBundleProduct(id);

                this.bundleProducts = this.bundleProducts.filter(item => item.id !== id);

                this.$toaster('Product has been removed!');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.add-button {
    height: 38px;
    width: 170px;
}
</style>

<template>
    <layout>
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onSaveBundle"
        />
        <page-header :title="pageTitle" :items="breadcrumbsItems" />

        <div class="card-box">
            <div class="text-center">
                <router-link to="/bundles">
                    <button
                        type="button"
                        class="btn w-sm btn-light waves-effect mx-1"
                    >
                        Cancel
                    </button>
                </router-link>
                <a
                    v-if="editMode"
                    :href="previewUrl"
                    target="_blank"
                    class="btn btn-info mx-1"
                >
                    <i class="far fa-eye mr-1" />
                    Preview
                </a>
                <button
                    type="button"
                    class="btn w-sm btn-success waves-effect waves-light mx-1"
                    @click="onSaveBundle"
                >
                    <i class="fas fa-save mr-1" />
                    Save
                </button>
            </div>
        </div>

        <div class="card">
            <ul class="nav nav-pills navtab-bg nav-justified m-2">
                <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                    <a
                        href="#"
                        data-toggle="tab"
                        aria-expanded="false"
                        class="nav-link"
                        :class="{
                            active: activeTab === tab.value
                        }"
                        @click.prevent="changeTab(tab)"
                    >
                        {{ tab.text }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-show="activeTab === 'bundle'">
            <div class="card-box">
                <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                </h5>
                <div class="form-group mb-3">
                    <label for="bundle-name">
                        Bundle Name
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        id="bundle-name"
                        v-model="bundle.name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.bundle.name.$error
                        }"
                        placeholder="Bundle Name"
                        @blur="onNameBlur"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="bundle-slug">
                        Slug
                        <span class="text-danger">*</span>
                    </label>
                    <input
                        id="bundle-slug"
                        v-model="bundle.slug"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.bundle.slug.$error || slugExists
                        }"
                        placeholder="Bundle slug"
                        @blur="onSlugBlur"
                    />
                    <div v-if="slugExists" class="invalid-feedback">
                        Slug already exists.
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="bundle-subnameTitle">
                        Description Headline
                    </label>
                    <input
                        id="bundle-subnameTitle"
                        v-model="bundle.subname_title"
                        class="form-control"
                        placeholder="Bundle Description Headline"
                        type="text"
                    />
                </div>
                <div class="form-group mb-3">
                    <label for="bundle-subname">
                        Description
                    </label>
                    <textarea
                        id="bundle-subname"
                        v-model="bundle.subname"
                        class="form-control"
                        placeholder="Bundle Description"
                        rows="3"
                    />
                </div>
            </div>

            <product-informative-sections
                :key="`${bundle.id}-informativeSections`"
                v-model="bundle.informative_sections"
                class="card-box"
            />

            <product-benefits
                :key="`${bundle.id}-benefits`"
                v-model="bundle.benefits"
                class="card-box"
            />

            <div class="card-box">
                <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
                    Options
                </h5>

                <div class="ml-2">
                    <b-form-checkbox
                        v-model="bundle.video_first"
                        name="enabled"
                        switch
                    >
                        Slider - Show Video First
                    </b-form-checkbox>
                </div>
            </div>

            <bundle-colors
                v-if="editMode && bundle.id"
                :bundle-id="bundle.id"
            />
        </div>

        <div v-show="activeTab === 'shop'">
            <div class="card-box">
                <div class="row m-0">
                    <h5 class="col-12 text-uppercase mt-0 mb-2 bg-light p-2">
                        Shop
                    </h5>
                    <media-chooser
                        v-model="bundle.shop_image"
                        add-buff
                        label="Image"
                        class="col-6"
                        recommended-size="570x620"
                    />
                    <media-chooser
                        v-model="bundle.shop_image_secondary"
                        add-buff
                        label="Mouseover Image"
                        class="col-6"
                        recommended-size="570x620"
                    />
                    <div class="form-group col-12">
                        <label>Name</label>
                        <textarea
                            v-model="bundle.shop_name"
                            class="form-control"
                            rows="1"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label>Tooltip</label>
                        <textarea
                            v-model="bundle.shop_tooltip"
                            class="form-control"
                            rows="2"
                        />
                    </div>
                    <div class="form-group col-12">
                        <label>Reviews Number</label>
                        <input
                            v-model="bundle.shop_reviews_number"
                            class="form-control"
                        />
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        Patch
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="bundle.shop_patch.enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <div class="form-group col-4">
                        <label>Text</label>
                        <input
                            v-model="bundle.shop_patch.text"
                            class="form-control"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label>Text Color</label>
                        <color-picker
                            :key="bundle.id"
                            v-model="bundle.shop_patch.textColor"
                            :color="bundle.shop_patch.textColor"
                        />
                    </div>
                    <div class="form-group col-4">
                        <label>Background Color</label>
                        <color-picker
                            :key="bundle.id"
                            v-model="bundle.shop_patch.backgroundColor"
                            :color="bundle.shop_patch.backgroundColor"
                        />
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        CTA button
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="bundle.cta_button_enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <h5 class="col-12 text-uppercase bg-light p-2">
                        Price text
                    </h5>
                    <div class="col-12 my-2">
                        <b-form-checkbox
                            v-model="bundle.price_text.enabled"
                            name="enabled"
                            switch
                        >
                            Enabled
                        </b-form-checkbox>
                    </div>
                    <div v-if="bundle.price_text.enabled" class="col-12 my-2">
                        <product-price-text v-model="bundle.price_text" />
                    </div>
                </div>
            </div>
        </div>

        <div v-show="activeTab === 'products'">
            <bundle-products
                v-if="bundle.bundleProducts"
                :bundle-id="bundle.id"
                :bundle-products-data="bundle.bundleProducts"
            />
        </div>

        <questionnaires-management
            v-if="editMode"
            v-show="activeTab === 'questionnaires'"
            :questionnaires="questionnaires"
            :bundle-slug="bundle.slug"
        />
    </layout>
</template>

<script>
import slug from 'slug';
import config from '@config';
import appConfig from '@src/app.config';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import BundleColors from '@components/bundles/BundleColors';
import BundleProducts from '@components/bundles/BundleProducts';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import WereChangesAlert from '@components/alerts/WereChangesAlert';
import ProductBenefits from '@components/products/ProductBenefits';
import ProductPriceText from '@components/products/ProductPriceText';
import ProductInformativeSections from '@components/products/ProductInformativeSections';
import QuestionnairesManagement from '@components/questionnaires/QuestionnairesManagement';

export default {
    page: {
        title: 'Bundle Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    validations: {
        bundle: {
            name: {
                required
            },
            slug: {
                required
            }
        }
    },

    components: {
        BundleColors,
        BundleProducts,
        MediaChooser,
        ColorPicker,
        WereChangesAlert,
        ProductBenefits,
        ProductPriceText,
        ProductInformativeSections,
        QuestionnairesManagement
    },

    props: {
        bundleId: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            pageTitle: 'Bundle Details',
            breadcrumbsItems: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Bundles',
                    to: '/bundles'
                },
                {
                    text: 'Details',
                    active: true
                }
            ],
            tabs: [
                {
                    value: 'bundle',
                    text: 'Bundle Information'
                },
                {
                    value: 'shop',
                    text: 'Shop Information'
                },
                {
                    value: 'questionnaires',
                    text: 'Questionnaires'
                },
                {
                    value: 'products',
                    text: 'Bundle Products'
                }
            ],
            activeTab: 'bundle',
            editMode: false,
            slugExists: false,
            bundle: {
                benefits: {},
                shop_patch: {},
                price_text: {},
                informative_sections: []
            },
            initValueStringify: '',
            questionnaires: []
        };
    },

    computed: {
        wereChanges() {
            if (!this.bundle || !this.editMode) {
                return false;
            }

            return JSON.stringify(this.bundle) !== this.initValueStringify;
        },

        previewUrl() {
            return `${config.frontendUrl}/bundles/${this.bundle.slug}`;
        }
    },

    async created() {
        if (!this.bundleId) {
            return;
        }

        this.editMode = true;
        this.bundle = await this.getBundle(this.bundleId);

        this.initValueStringify = JSON.stringify(this.bundle);

        this.questionnaires = [...this.bundle.questionnaires];
    },

    mounted() {
        if (this.$route.query.questionnaire) {
            this.activeTab = 'questionnaires';
        }
    },

    methods: {
        ...mapActions({
            getBundle: 'bundles/show',
            storeBundle: 'bundles/store',
            updateBundle: 'bundles/update'
        }),

        changeTab(tab) {
            this.activeTab = tab.value;
        },

        async onNameBlur() {
            if (!this.editMode && this.bundle.name) {
                this.bundle.slug = slug(this.bundle.name.toLowerCase());

                await this.$forceUpdate();
            }
        },

        async onSlugBlur() {
            if (this.bundle.slug) {
                this.bundle.slug = slug(this.bundle.slug.toLowerCase());

                await this.$forceUpdate();
            }
        },

        async onSaveBundle() {
            this.slugExists = false;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                if (!this.editMode) {
                    const bundle = await this.storeBundle(this.bundle);

                    this.$toaster('Bundle has been added!');

                    return this.$router.push(`/bundles/${bundle.id}/edit`);
                }

                this.bundle = await this.updateBundle(this.bundle)

                this.initValueStringify = JSON.stringify(this.bundle);
                this.$toaster('Bundle has been updated!');
            } catch (err) {
                console.error(err);

                if (
                    err.response.status === 422 &&
                    err.response.data.errors &&
                    err.response.data.errors.slug
                ) {
                    this.slugExists = true;

                    return;
                }

                this.$toasterError('Bundle update error!');
            }
        }
    }
};
</script>

<template>
    <div :key="sections.length">
        <h5 class="col-12 text-uppercase bg-light p-2">
            Informative sections
        </h5>
        <div v-for="(section, index) in sections" :key="index" class="col-12">
            <h5
                class="col-12 d-flex align-items-center justify-content-between text-uppercase bg-light p-2"
            >
                <div> Section {{ index + 1 }} </div>
                <button
                    class="btn btn-danger waves-effect waves-light"
                    type="button"
                    @click="deleteItem(index)"
                >
                    <i class="far fa-trash-alt" />
                </button>
            </h5>
            <div class="form-group col-12">
                <label :for="`title${index}`">Title</label>
                <input
                    :id="`title${index}`"
                    v-model="section.title"
                    class="form-control"
                    type="text"
                    @input="onChange"
                />
            </div>
            <div class="form-group col-12">
                <label :for="`content${index}`">Content</label>
                <textarea
                    :id="`content${index}`"
                    v-model="section.content"
                    class="form-control"
                    rows="5"
                    @input="onChange"
                />
            </div>
        </div>

        <div class="col-12 mt-3">
            <button
                class="btn btn-info waves-effect waves-light"
                type="button"
                @click="addItem"
            >
                <i class="fas fa-plus" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            sections: []
        };
    },

    created() {
        this.sections = [...this.value];
    },

    methods: {
        onChange() {
            this.$emit('input', this.sections);
        },

        addItem() {
            if (this.sections.length) {
                const last = [...this.sections].pop();

                if (!last.title || !last.content) {
                    this.$toasterWarning(
                        'Please fill the previous section before adding a new one!'
                    );

                    return;
                }
            }

            this.sections = [...this.sections, { title: '', content: '' }];
        },

        deleteItem(index) {
            this.sections.splice(index, 1);

            this.$emit('input', this.sections);
        }
    }
};
</script>

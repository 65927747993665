<template>
    <div class="row mt-2">
        <div class="col-12 form-group">
            <label for="background_color">Background color</label>
            <color-picker
                id="background_color"
                v-model="price_text.backgroundColor"
                :color="price_text.backgroundColor"
                @input="emitBenefits"
            />
        </div>
        <div class="col-12 form-group">
            <wysiwyg-editor
                v-model="price_text.content"
                label="Content"
                @input="emitBenefits"
            />
        </div>
        <div class="col-12">
            <div class="alert alert-blue border-0">
                <i class="fas fa-info mr-1" />
                In 'Content' you can use variable
                <b>{Codes}</b>.
            </div>
        </div>
    </div>
</template>

<script>
import ColorPicker from '@components/elements/ColorPicker';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        ColorPicker,
        WysiwygEditor
    },

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            price_text: {}
        };
    },

    created() {
        this.price_text = { ...this.value };
    },

    methods: {
        emitBenefits() {
            this.$emit('input', this.price_text);
        }
    }
};
</script>

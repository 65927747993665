<template>
    <table
        id="orders-datatable"
        class="table table-centered table-striped text-center my-1"
    >
        <thead class="d-flex col-12">
            <tr class="d-flex col-12">
                <th class="col-1">
                    Order
                </th>
                <th class="col-1">
                    Question type
                </th>
                <th class="col-2">
                    Title
                </th>
                <th class="col-3">
                    Subtitle
                </th>
                <th class="col-2">
                    Subquestion
                </th>
                <th class="col-2">
                    Answer variable
                </th>
                <th class="text-center col-1">
                    Actions
                </th>
            </tr>
        </thead>
        <tbody>
            <draggable
                class="col-12"
                :list="draggableElements"
                ghost-class="ghost"
                v-bind="dragOptions"
                @end="onElementMoved"
                @start="drag = true"
            >
                <transition-group
                    type="transition"
                    :name="!drag ? 'flip-list' : null"
                    class="col-12 d-flex flex-wrap"
                    style="display:block"
                >
                    <tr
                        v-for="(question, index) in draggableElements"
                        :key="question.id"
                        class="col-12 d-flex"
                        style="cursor: grab"
                    >
                        <td class="col-1">
                            {{ index + 1 }}
                        </td>
                        <td class="col-1">
                            {{ question.type }}
                        </td>
                        <td class="col-2" v-html="question.title" />
                        <td class="col-3" v-html="question.subtitle" />
                        <td class="col-2">
                            <span
                                v-if="question.subquestion"
                                class="badge bg-soft-success text-success"
                            >
                                Yes
                            </span>
                            <span
                                v-else
                                class="badge bg-soft-danger text-danger"
                            >
                                No
                            </span>
                        </td>
                        <td class="col-2">
                            {{ question.answer_variable }}
                        </td>
                        <td class="col-1 text-center">
                            <a
                                href="#"
                                class="action-icon"
                                @click.prevent="onEditElementButton(question)"
                            >
                                <i class="fas fa-pen mr-1" />
                            </a>
                            <a
                                href="#"
                                class="action-icon"
                                @click.prevent="
                                    onDeleteElementButton(question, index)
                                "
                            >
                                <i class="fas fa-trash-alt" />
                            </a>
                        </td>
                    </tr>
                </transition-group>
            </draggable>
            <div v-if="loadingQuestion" class="col-12">
                <div class="col-12">
                    <tr
                        :key="loadingQuestion.id"
                        class="col-12 d-flex"
                        style="cursor: no-drop"
                    >
                        <td class="col-1">
                            {{ loadingQuestion.index + 1 }}
                        </td>
                        <td class="col-1">
                            {{ loadingQuestion.type }}
                        </td>
                        <td class="col-2">
                            {{ loadingQuestion.title }}
                        </td>
                        <td class="col-3">
                            {{ loadingQuestion.subtitle }}
                        </td>
                        <td class="col-2">
                            <span
                                v-if="loadingQuestion.subquestion"
                                class="badge bg-soft-success text-success"
                            >
                                Yes
                            </span>
                            <span
                                v-else
                                class="badge bg-soft-danger text-danger"
                            >
                                No
                            </span>
                        </td>
                        <td class="col-2">
                            {{ loadingQuestion.answer_variable }}
                        </td>
                        <td class="col-1 text-center">
                            <a
                                href="#"
                                class="action-icon"
                                @click.prevent="
                                    onEditElementButton(loadingQuestion)
                                "
                            >
                                <i class="fas fa-pen mr-1" />
                            </a>
                            <a
                                href="#"
                                class="action-icon"
                                @click.prevent="
                                    onDeleteElementButton(
                                        loadingQuestion,
                                        loadingQuestion.index
                                    )
                                "
                            >
                                <i class="fas fa-trash-alt" />
                            </a>
                        </td>
                    </tr>
                </div>
            </div>
        </tbody>
    </table>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
    components: {
        Draggable
    },

    props: {
        questions: {
            type: [Array, Object],
            required: true
        }
    },

    data() {
        return {
            drag: false,
            loadingQuestion: null
        };
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
                filter: '.is-not-draggable'
            };
        },

        draggableElements() {
            let questionWithoutLoadingQuestion = this.questions.filter(
                item => item !== this.loadingQuestion
            );

            return [...questionWithoutLoadingQuestion];
        }
    },

    mounted() {
        this.loadingQuestion = this.questions.find(
            item => item.type === 'loading'
        );

        if (this.loadingQuestion) {
            this.loadingQuestion.index = this.questions.findIndex(
                item => item === this.loadingQuestion
            );
        }
    },

    methods: {
        onEditElementButton(question) {
            this.$emit('editElement', question);
        },

        onDeleteElementButton(question, index) {
            this.$emit('elementDeleted', question, index);
        },

        onElementMoved() {
            this.drag = false;

            const newOrdersArray = [...this.draggableElements];

            if (this.loadingQuestion) {
                newOrdersArray.push(this.loadingQuestion);
            }

            this.$emit('elementMoved', newOrdersArray);
        }
    }
};
</script>

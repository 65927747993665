<template>
    <b-modal
        id="modalQuestionnaireCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title">
            {{ updatingQuestionnaire ? 'Edit' : 'Add New' }} Questionnaire
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="type">Type</label>
                    <b-form-select
                        id="type"
                        v-model="data.type"
                        :class="{ 'is-invalid': $v.data.type.$error }"
                        :options="typeOptions"
                    />
                </div>
                <div class="form-group">
                    <label for="urlModifier">Url Modifier</label>
                    <input
                        id="urlModifier"
                        v-model="data.name"
                        :class="{ 'is-invalid': $v.data.name.$error }"
                        type="text"
                        class="form-control"
                        autocomplete="off"
                    />
                </div>
                <div class="form-group">
                    <label for="url">Url</label>
                    <input
                        id="url"
                        :value="url"
                        type="text"
                        class="form-control"
                        readonly="readonly"
                    />
                </div>
                <div v-if="!updatingQuestionnaire" class="form-group">
                    <b-form-checkbox v-model="importQuestions" switch>
                        Import questions
                    </b-form-checkbox>
                </div>
                <div
                    v-if="!updatingQuestionnaire && importQuestions"
                    class="form-group"
                >
                    <b-form-select
                        v-model="questionnaireImportName"
                        :options="options"
                    />
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="onSubmit"
                >
                    {{ updatingQuestionnaire ? 'Update' : 'Save' }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import slug from 'slug';
import { required } from 'vuelidate/lib/validators';

export default {
    props: {
        updatingQuestionnaire: {
            type: Boolean,
            required: false,
            default: false
        },
        questionnaire: {
            type: Object,
            required: false,
            default: null
        },
        questionnaires: {
            type: Array,
            required: false,
            default: null
        },
        isProductPage: {
            type: Boolean,
            required: false,
            default: false
        },
        isBundlePage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        const types = ['full', 'quiz'];

        if (!this.isProductPage && !this.isBundlePage) {
            types.push('post-purchase');
        }

        return {
            data: {
                type: '',
                name: ''
            },
            typeOptions: types,
            importQuestions: false,
            questionnaireImportName: null
        };
    },

    computed: {
        options() {
            return this.questionnaires.map(item => item.name);
        },

        url() {
            const questionnaireFullName = `${this.data.type || ''}
                ${this.data.name && this.data.type ? '-' : ''}
                ${this.data.name || ''}`;

            const route = this.data.type === 'quiz' ? 'quiz' : 'questionnaire';

            const prefix = this.isProductPage ? 'product-' : this.isBundlePage ? 'bundle-' : '';

            const query = slug(questionnaireFullName.toLowerCase().trim());

            return `/${route}?id=${prefix}${query}`;
        }
    },

    validations: {
        data: {
            type: { required },
            name: { required }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalQuestionnaireCreateEdit');
            this.$emit('hidden');
            this.data = {};
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.questionnaire && this.updatingQuestionnaire) {
                this.data = { ...this.questionnaire };
            } else {
                this.data = {};
            }
        },

        onSubmit() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.data.isPostPurchase = this.data.type === 'post-purchase';

            if (this.updatingQuestionnaire) {
                this.$emit('update-questionnaire', this.data);
            } else {
                const questionnaire = { ...this.data };

                if (this.importQuestions && this.questionnaireImportName) {
                    const questionnaireIndex = this.questionnaires.findIndex(
                        item => item.name === this.questionnaireImportName
                    );

                    questionnaire.questionnaireImportId = this.questionnaires[
                        questionnaireIndex
                    ].id;
                }

                this.$emit('create-questionnaire', questionnaire);

                this.importQuestions = false;
                this.questionnaireImportName = null;
            }

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>
